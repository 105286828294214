$green-text-color: #006100;
$warning-text-color: #CC0000;
$background-color-primary: #408540;
$background-color-secondary: lighten(saturate(adjust-hue($background-color-primary, -24), 9), 9);
$background-color-modal: lighten( saturate( $background-color-secondary, 5 ), 40 );
$btn-primary: #FFC32B;
$btn-primary-hover: lighten( $btn-primary, 10 );

// Copied from Bootstrap 5 without system-ui because of
// https://infinnie.github.io/blog/2017/systemui.html
$font-family-sans-serif:
  // Safari for macOS and iOS (San Francisco)
  -apple-system,
  // Windows
  "Segoe UI",
  // Android
  Roboto,
  // older macOS and iOS
  "Helvetica Neue",
  // Linux
  "Noto Sans",
  "Liberation Sans",
  // Basic web fallback
  Arial,
  // Sans serif fallback
  sans-serif,
  // Emoji fonts
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
