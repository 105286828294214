a.navbar-toggle {
    color: #fff;
}

#header {
    .navbar-nav > li > a {
        overflow: hidden;
        color: #FFFFFF;
        text-shadow: unset;

        &:hover,
        &:active,
        &:focus {
            background-color: transparent;
            text-decoration: underline 2px;
        }
    }

    .dropdown-menu {
        background-color: rgb(53, 44, 46);
        font-size: 13px;

        li a {
            color: #FFFFFF;
            &:hover,
            &:focus {
                background: $background-color-primary none;
                text-decoration: none;
            }
        }
    }

}

#logo {
    display: flex;
    align-items: center;
    padding: .2em .5em;

    img {
        height: 80%;
    }
}

#toplevelmenu {
    background-color: transparent;
    box-shadow: none;
    position: relative;
    left: 0;
    top: 0;
}

#header_search {
    background-color: $background-color-primary;
    border-radius: 0;
    border: 1px solid $background-color-primary;
    display: flex;
    padding: 0 .8em;

    ul {
        padding: 0;
        margin: 0;
        border-radius: 0 16px 16px 0;
        display: flex;
        align-items: center;
        color: white;
        background-color: $background-color-primary;
        z-index: 2;
        flex-grow: 1;

        li {
            background: transparent;
            border: 0;
            border-radius: 0;

            border: 0;
            border-bottom-width: 0;
            padding-bottom: 0;
            list-style-type: none;
            margin: 0;
            white-space: nowrap;

            a {
                color: #FFF;
                padding: 0 .25em;
                margin: 0 .25em;
                border: 2px solid transparent;
            }
        }
    }

    form {
        display: flex;
        position: relative;
    }

    .form-title {
        padding: 0 16px 0 0;
        background-color: $background-color-primary;
        border-radius: 0 16px 16px 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        z-index: 2;
        white-space: nowrap;
        flex-basis: 15%;

        label {
            color: white;
            font-weight: bold;
            margin: 0;
            white-space: nowrap;
        }
    }

    .form-content {
        background-color: white;
        margin-left: -16px;
        padding-left: 16px;
        border-radius: 0 16px 16px 0;
        white-space: nowrap;
        z-index: 1;
        display: flex;
        flex-grow: 1;

        input[type="text"],
        select {
            background-color: transparent;
            border: 0;
            border-radius: 0;
            box-shadow: unset;
            margin: 0;

            &:not(:first-child) {
                border-left: 1px solid #e0e0e0;
            }
        }

        input + input {
            padding-right: .5em;
        }
    }

    .form-control {
        font-size: 1em;
    }

    .form-extra-content {
        right: 60px;
        position: absolute;
        top: 31px;
        background-color: #f3f4f4;
        background-color: white;
        padding: 1em;
        border-radius: 0 0 8px 8px;
        border: 1px solid $background-color-primary;
        border-top: 0 none;
        box-shadow: 0 2px 2px 1px #00000030;
        z-index: 1;
        display: none;

        a {
            color: $green-text-color;
        }

        div {
            margin-bottom: .5em;
        }

        .form-control {
            display: block;
            width: 100%;
        }

        label {
            display: block;
            margin-bottom: 0;
        }
    }

    .form-extra-content-toggle {
        background-color: transparent;
        opacity: .5;
        padding-right: 1em;

        &:hover {
            opacity: 1;
        }
    }

    input[type="submit"],
    button[type="submit"] {
        height: 31px;
        background-color: $background-color-secondary;
        color: white;
        border: 0;
        text-shadow: unset;
        border-radius: 0 16px 16px 0;
        margin-left: -16px;
        margin-right: 1em;
        padding-left: calc( 16px + .7em );
        padding-right: .9em;

        &:hover {
            background-color: lighten( $background-color-secondary, 5 );
        }
    }

    input[type="button"], button {
        height: 31px;
        border: 0;
        text-shadow: unset;

        &:active {
            box-shadow: unset;
        }
    }

    .tab-content {
        background-color: transparent;
        padding: 0;

        &:first-of-type {
            flex-basis: 50%;
        }
    }

    .nav-tabs {
        > li {

            > a {
                background-color: $background-color-primary;
                border: 2px solid $background-color-primary;
                border-radius: 0;
                color: #FFF;
                font-weight: normal;
                line-height: 1.3;
                margin: 0 .25em;
                padding: 0 .25em;

                &:hover, &:focus, &:active {
                    background-color: $background-color-primary;
                    border: 2px solid $background-color-primary;
                    border-bottom-color: $background-color-secondary;
                    border-bottom: 0;
                    padding: 0 .25em;
                    text-decoration: none;
                }
            }

            &.active {
                a {
                    cursor: default;
                    padding: 0 .25em;
                    border-bottom: 2px solid #FFF;

                    &:hover,
                    &:focus {
                    }
                }
            }
        }
    }
}

#logged-in-menu {
    &:hover, &:active, &:focus {
        text-decoration: none;
    }
}

#lastborrower-window {
    display: none;
    background-color: $background-color-primary;
    box-shadow: 1px 1px 1px 0 #999;
    color: #FFFFFF;
    padding: .2em;
    border-radius: 5px 5px 5px 5px;

    > * {
        padding: 0 .4em;
    }
}

#lastborrower-remove {
    cursor: pointer;
    border-left: 1px solid #fff;
}

#lastborrowerlink {
    color: #FFFFFF;
}

@media (max-width: 768px) {
    #header_search {
        .form-title {
            display: none;
        }
    }
}

@media (max-width: 992px) {
    #header_search {
        .residentsearch {
            flex-grow: 1;
        }

        .tab-title {
            display: none;
        }

        > ul {
            flex-grow: 0;
        }
    }
}
