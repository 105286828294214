#toolbar,
.btn-toolbar {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    background-color: #f3F4F4;
    border: 0;

    margin-bottom: 5px;
    padding: 5px;

    .dropdown-menu {
        border-top-width: 1px;
        font-size: 13px;
    }

    &.floating {
        border-radius: 0;
        box-shadow: 0 0 2px 1px rgba(0,0,0,.4);
        margin-top: 0;
        z-index: 100;
    }

    .btn {
        &.btn-default {
            font-weight: normal;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            touch-action: manipulation;
            cursor: pointer;
            background-image: none;
            padding: 6px 12px;
            box-shadow: none;
            border-radius: 4px;
            user-select: none;
            font-size: 100%;
            background-color: transparent;
            border: 0;
            display: inline-block;
            color: #696969;

            &:hover, &:focus {
                text-decoration: none;
                background-color: #DADADA;
                color: #696969;
                padding: 6px 12px;
            }

            &:active {
                border: 0;
            }

            &.addtocart {
                display: block;

                &.incart {
                    display: none;
                }
            }

            &.cartRemove {
                padding: 6px 12px;
                font-size: 12px;
                display: none;

                &.incart {
                    display: block;
                }
            }
        }

        &.btn-link {
            font-size: 100%;
        }
    }

    &.selections-toolbar {
        a {
            display: inline-block;
            padding: 0 .5em;
        }
    }
}
