@font-face {
    font-family: "poppins";
    font-weight: 400;
    font-style: italic;
    src: local("Poppins-Italic"),
         url("fonts/poppins-italic-webfont.woff2") format("woff2"),
         url("fonts/poppins-italic-webfont.woff") format("woff");
}

@font-face {
    font-family: "poppins";
    font-style: 700;
    font-weight: italic;
    src: local("Poppins-BoldItalic"),
         url("fonts/poppins-bolditalic-webfont.woff2") format("woff2"),
         url("fonts/poppins-bolditalic-webfont.woff") format("woff");
}

@font-face {
    font-family: "poppins";
    font-style: normal;
    font-weight: 400;
    src: local("Poppins-Regular"),
         url("fonts/poppins-regular-webfont.woff2") format("woff2"),
         url("fonts/poppins-regular-webfont.woff") format("woff");
}

@font-face {
    font-family: "poppins";
    font-style: normal;
    font-weight: 700;
    src: local("Poppins-Bold"),
         url("fonts/poppins-bold-webfont.woff2") format("woff2"),
         url("fonts/poppins-bold-webfont.woff") format("woff");
}
