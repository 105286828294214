table {
    border-collapse: collapse;

    thead {
        border-bottom: 2px solid $table-border-color;
    }

    tfoot {
        border-top: 2px solid $table-border-color;
    }

    .btn-group {
        white-space: nowrap;

        .btn {
            display: inline-block;
            float: none;
        }
    }

    &.indexes {
        td {
            vertical-align: middle;
        }
    }

    > caption {
        span {
            &.actions {
                font-size: 66%;
                font-weight: normal;
                margin: 0 .5em 0 0;
            }
        }

        button {
            &.dt-button {
                background: transparent none;
                border: 0;
                color: #595959;
                font-size: 60%;
                margin: 0 .5em;

                &:hover:not( .disabled ):active:not( .disabled ) {
                    background: transparent none;
                    border: 0;
                    box-shadow: none;
                    color: #000;
                }

                &:hover:not( .disabled ) {
                    background: transparent none;
                    border: 0;
                    color: #000;
                }
            }
        }

        div.dt-button-collection {
            font-size: 70%;
        }
    }

    &.invis {
        border: 0;

        tr,
        td {
            border: 0;
        }
    }

    + table {
        margin-top: 1em;
    }

    &.dataTable {
        border-collapse: collapse;
        margin-bottom: .5em;
        margin-top: .5em;

        &.fixedHeader-floating {
            margin-top: 0;
        }

        tfoot {
            td,
            th {
                padding: .5em;
            }
        }

        thead {
            th,
            td {
                border-bottom: 1px solid $table-border-color;
                border-left: 1px solid $table-border-color;
                padding: .5em;

                &:first-child {
                    border-left: 0;
                }

                &:last-child {
                    border-right: 0;
                }
            }

            tr {
                &.columnFilter {
                    th {
                        padding: 0;
                    }
                }
            }

            .sorting_asc {
                background: url( "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2bW0iIGhlaWdodD0iMi4zODRtbSIgdmlld0JveD0iMCAwIDYuMDAwMDAwMiAyLjM4NDAzODkiPjxnPjxwYXRoIGZpbGw9IiMwMDZhY2MiIGQ9Ik00LjA1IDEuNzRoLTIuMUwzIC42NHoiLz48L2c+PC9zdmc+" ) no-repeat scroll right center $table-header-background;
                padding-right: 19px;

                &.fixed_sort {
                    background: url( "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2bW0iIGhlaWdodD0iOS4wMSIgdmlld0JveD0iMCAwIDYgMi4zODQiPjxwYXRoIGZpbGw9IiM5NDk0OTQiIGQ9Ik00LjA1IDEuNzRoLTIuMUwzIC42NHoiLz48L3N2Zz4=" ) no-repeat scroll right center $table-header-background;
                    cursor: default;
                }
            }

            .sorting_desc {
                background: url( "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2bW0iIGhlaWdodD0iMi4zODRtbSIgdmlld0JveD0iMCAwIDYuMDAwMDAwMiAyLjM4NDAzODkiPjxnPjxwYXRoIGZpbGw9IiMwMDZhY2MiIGQ9Ik00LjA1LjY0aC0yLjFMMyAxLjc0eiIvPjwvZz48L3N2Zz4=" ) no-repeat scroll right center $table-header-background;
                padding-right: 19px;

                &.fixed_sort {
                    background: url( "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2bW0iIGhlaWdodD0iOS4wMSIgdmlld0JveD0iMCAwIDYgMi4zODQiPjxwYXRoIGZpbGw9IiM5NDk0OTQiIGQ9Ik00LjA1LjY0aC0yLjFMMyAxLjc0eiIvPjwvc3ZnPg==" ) no-repeat scroll right center $table-header-background;
                    cursor: default;
                }
            }

            .sorting:not(.sorting_asc):not(.sorting_desc) {
                background: url( "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2bW0iIGhlaWdodD0iMi41NTFtbSIgdmlld0JveD0iMCAwIDYuMDAwMDAwMyAyLjU1MDY2MTQiPjxwYXRoIGZpbGw9IiNhY2FjYWMiIGQ9Ik00LjA1IDEuNDVoLTIuMUwzIDIuNTV6Ii8+PHBhdGggZmlsbD0iI2FjYWNhYyIgZD0iTTQuMDUgMS4xaC0yLjFMMyAweiIvPjwvc3ZnPg==" ) no-repeat scroll right center $table-header-background;
                padding-right: 19px;
            }
        }

        tbody {
            td {
                padding: .5em;
            }
        }
    }

    /* Row grouping */
    &.group {
        tr {
            &.odd td {
                background-color: #F3F3F3;
                border: 1px solid $table-border-color;
                border-right: 1px solid $table-border-color;
            }

            &.even td {
                background-color: #FFF;
                border-right: 1px solid $table-border-color;
            }
        }

        td.group {
            background-color: #D1CFD0;
            border-bottom: 2px solid #A19B9E;
            border-top: 2px solid #A19B9E;
        }
    }
}

td,
th {
    border-bottom: 1px solid $table-border-color;
    border-left: 1px solid $table-border-color;
    padding: .2em .9em;

    &:first-child {
        border-left: none;
    }

    &:last-child {
        border-right: none;
    }
}

td {
    background-color: #FFFFFF;
    vertical-align: top;

    &.actions {
        white-space: nowrap;
    }

    &.data {
        font-family: $font-monospace;
        text-align: right;
    }

    &.total {
        text-align: right;
    }

    input {
        &.approve {
            background-color: #FFC;
        }
    }

    /* Row grouping */
    &.group {
        background-color: #D1CFD0;
        border-bottom: 2px solid #A19B9E;
        border-top: 2px solid #A19B9E;
    }
}

th {
    background-color: $table-header-background;
    font-weight: bold;
    text-align: center;

    &[scope="row"] {
        text-align: right;
    }

    &.data {
        font-family: $font-monospace;
        text-align: right;
    }
}

tr {
    &.clickable {
        cursor: pointer;
    }

    &.expired {
        td {
            color: #999999;
        }
    }

    &.highlight {
        td {
            background-color: #F6F6F6;
            border-color: $table-border-color;
        }

        th {
            &[scope="row"] {
                background-color: #DDDDDD;
                border-color: $table-border-color;
            }
        }

        table {
            &.invis {
                td {
                    border: 0;
                }
            }
        }
    }

    &.odd {
        &.onissue {
            td {
                background-color: #FFFFE1;
            }
        }
    }

    &.ok {
        td {
            background-color: #FFFFCC;
        }

        &:nth-child( odd ) {
            td {
                background-color: #FFFFCC;
            }
        }

        &:nth-child( even ) {
            td {
                background-color: #FFFFCC;
            }
        }
    }

    &.onissue {
        td {
            background-color: #FFFFCC;
        }
    }

    &.reserved {
        td {
            background-color: #EEFFD4;
        }
    }

    &.transfered {
        td {
            background-color: #E8F0F6;
        }
    }

    &.warn {
        td {
            background-color: #FF9090;
        }

        &:nth-child( odd ) {
            td {
                background-color: #FF9090;
            }
        }
    }

    &.default {
        td {
            font-weight: bold;
        }
    }

    &.debug {
        td {
            color: #CC0000;
            font-style: italic;
            font-weight: bold;
        }
    }
}

.selections-table {
    tr {
        &:hover {
            td {
                background-color: #FFFF99 !important;
            }
        }
    }
}

tfoot {
    td {
        background-color: $table-odd-row;
        font-weight: bold;
        vertical-align: middle;
    }
}

caption {
    color: #000;
    font-size: 133.9%;
    font-weight: bold;
    margin: .3em 0;
}

.highlighted-row,
.highlighted-row td {
    background-color: #FFD000 !important;
}

.warned-row,
.warned-row td { background-color: #FF9000 !important; }

.high-warned-row,
.high-warned-row td { background-color: #FF0000 !important; }

tbody {
    tr {
        &:nth-child( odd ):not( .dtrg-group, .active, .ok ) {
            td {
                &:not( .bg-danger, .bg-warning, .bg-info, .bg-success, .bg-primary ) {
                    background-color: $table-odd-row;
                }
            }
        }
    }
}

.dataTables_wrapper {
    .dataTables_processing {
        background-color: #F4F8F9;
        border: 2px solid #538200;
        border-radius: 3px;
        box-shadow: 2px 2px 3px 1px rgba( 0, 0, 0, .2 );
        color: #333;
        height: unset;
        left: 50%;
        margin-left: -125px;
        margin-top: -15px;
        padding: 1.5em;
        position: fixed;
        text-align: center;
        top: 50%;
        width: auto;
    }

    .dataTables_info {
        float: none;
        line-height: 1.9em;
        padding-right: 1em;
        padding-top: 0;
    }

    .dataTables_length {
        display: none;
        float: none;
        line-height: 1.5em;
        padding-right: 1em;
    }

    .dataTables_filter {
        float: none;
        padding-right: 1em;
        white-space: nowrap;
    }

    .dt-buttons {
        display: flex;
    }

    .dataTables_paginate {
        float: none;
        line-height: 1.5em;
        padding: 0;
        padding-right: 1em;

        span {
            .paginate_button,
            .ellipsis {
                display: none;
            }
        }

        .paginate_button {
            border-radius: 4px;
            color: $green-text-color !important;
            padding-bottom: 0;
            padding-top: 0;

            &:hover,
            &:active {
                background: transparent none;
                border: 1px solid transparent;
                box-shadow: none;
                color: darken( $green-text-color, 5 ) !important;
                text-decoration: underline !important;
            }

            &.current {
                background: lighten( saturate( $background-color-secondary, 7 ), 37 ) none;
                border: 0;
                color: #666;
                font-weight: bold;

                &:hover,
                &:active {
                    background: lighten( saturate( $background-color-secondary, 7 ), 45 ) none;
                    border: 0;
                    box-shadow: none;
                    color: #666;
                    cursor: default;
                }
            }

            &.first,
            &.previous,
            &.next,
            &.last {
                &::before,
                &::after {
                    display: inline-block;
                    font-family: FontAwesome;
                    width: 1em;
                }
            }

            &.first {
                display: none;

                &::before {
                    content: "\f100";
                    padding-right: .5em;
                }
            }

            &.previous {
                &::before {
                    content: "\f104";
                    padding-right: .5em;
                }
            }

            &.next {
                &::after {
                    content: "\f105";
                    padding-left: .5em;
                }
            }

            &.last {
                display: none;

                &::after {
                    content: "\f101";
                    padding-left: .5em;
                }
            }
        }
    }
}

.dt-info {
    margin-top: .5em;
}

.dt-info + .pager {
    border-top: 1px solid $table-border-color;
    margin-top: .3em;
}

.pager {
    display: flex;
    flex-wrap: wrap;
    font-size: 90%;

    &.top {
        // border-top: 1px solid $table-border-color;
        padding-top: 1em;
    }

    &.bottom {
        border-bottom: 1px solid $table-border-color;
        padding-bottom: 1em;
    }

    div.dt-buttons {
        display: flex;
        flex-grow: 1;

        button:first-of-type {
            margin-right: auto;
        }
    }

    input {
        &[type="search"] {
            border: 1px solid #CCC;
        }
    }

    &:empty {
        border: 0;
        padding: 0;
    }
} /* /.pager */

button,
div,
a {
    &.dt-button {
        background: transparent none;
        border: 1px solid transparent;
        border-radius: 4px;
        color: #000;
        font-size: 1em;
        line-height: 1.7em;
        margin: 0 3px 0;
        padding: 0 .5em;

        &::before {
            content: "";
        }

        &.disabled {
            background: transparent none;
            border: 1px solid transparent;
            color: #999;
            cursor: default;

            &:focus {
                border: 1px solid #ADADAD;
            }

            a,
            span {
                background: transparent none;
            }
        }

        &:active:not( .disabled ),
        &:focus:not( .disabled ) {
            background: transparent none;
            border: 1px solid #ADADAD;
            box-shadow: none;
            text-shadow: none;

            &:not( .disabled ):hover:not( .disabled ) {
                background: #EEE none;
                border: 1px solid transparent;
                box-shadow: none;
            }
        }

        &:hover {
            background: transparent none;
            border: 1px solid transparent;

            &:not( .disabled ) {
                background: #EEE none;
            }
        }

        &.active:not( .disabled ):hover:not( .disabled ) {
            background: #EEE none;
            border: 1px solid transparent;
        }
    }
}

.toptabs {
    .pager {
        margin: 0;
        padding-bottom: .7em;
        padding-top: .7em;

        &.bottom {
            border-bottom: 0;
            padding-bottom: 0;
        }
    }
}

.filter_column {
    & > input {
        &[type="text"] {
            border: 0;
            color: #999;
            font-size: 85%;
            width: 100%;
        }
    }
}

div {
    &.dt-button-collection {
        width: auto;

        button {
            &.dt-button {
                border-radius: 2px;
                text-align: left;

                &.buttons-collection {
                    border: 0;
                }

                &.buttons-columnVisibility {
                    background: #FFF none;
                    border: 1px solid #EEE;
                    box-shadow: none;
                    font-size: 1em;
                    margin: 0 0 4px 0;
                    padding: .3em .7em;
                    text-shadow: none;

                    &::before {
                        color: #CC0000;
                        content: "\f00d";
                        display: inline-block;
                        font-family: FontAwesome;
                        margin-right: .5em;
                        width: 1em;
                    }

                    &:hover {
                        background: #FFC none;
                        border: 1px solid #999;
                        box-shadow: none;

                        &::before {
                            color: #538200;
                            content: "\f00c";
                            display: inline-block;
                            font-family: FontAwesome;
                            margin-right: .5em;
                            width: 1em;
                        }
                    }

                    &:active:not( .disabled ):hover:not( .disabled ) {
                        background: transparent none;
                        box-shadow: none;
                    }

                    &.active {
                        background: #E6F0F2 none;
                        border-color: #999;
                        box-shadow: none;

                        &:hover {
                            background: #FFC none;

                            &::before {
                                color: #CC0000;
                                content: "\f00d";
                                display: inline-block;
                                font-family: FontAwesome;
                                margin-right: .5em;
                                width: 1em;
                            }
                        }

                        &:not( .disabled ):hover:not( .disabled ) {
                            background: transparent none;
                            box-shadow: none;
                        }

                        &::before {
                            color: #538200;
                            content: "\f00c";
                            display: inline-block;
                            font-family: FontAwesome;
                            margin-right: .5em;
                            width: 1em;
                        }
                    }
                }

                &.buttons-html5,
                &.buttons-print {
                    background: #FFF none;
                    border: 1px solid #EEE;
                    box-shadow: none;
                    font-size: 1.2em;
                    margin: 0 0 4px 0;
                    padding: .3em .7em;
                    text-shadow: none;

                    &:hover {
                        background: #FFC none;
                    }
                }

                &.buttons-html5:not( .disabled )::before {
                    color: #222BAC;
                    display: inline-block;
                    font-family: FontAwesome;
                    margin-right: .5em;
                    width: 1em;
                }

                &.buttons-excel:not( .disabled )::before {
                    content: "\f1c3";
                }

                &.buttons-csv:not( .disabled )::before {
                    content: "\f0ce";
                }

                &.buttons-copy:not( .disabled )::before {
                    content: "\f0c5";
                }

                &.buttons-print:not( .disabled )::before {
                    color: #222BAC;
                    content: "\f02f";
                    display: inline-block;
                    font-family: FontAwesome;
                    margin-right: .5em;
                    width: 1em;
                }
            }
        }
    }
}

.table_entries {
    clear: both;
    display: flex;
}

.table_controls {
    border-top: 1px solid #EEE;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    margin-top: .5em;
    padding-top: .5em;
}

@media ( min-width: 1275px ) {
    .dt-button-text {
        display: inline;
    }

    .dataTables_wrapper {
        .dataTables_paginate {
            .paginate_button {
                &.first,
                &.last {
                    display: inline-block;
                }
            }
        }
    }
}

@media only screen and ( min-width: 500px ) {
    .dataTables_wrapper {
        .dataTables_length {
            display: block;
        }
    }
}

@media only screen and ( min-width: 850px ) {

}

@media only screen and ( max-width: 767px ) {
    .dataTables_wrapper {
        .dataTables_info,
        .dataTables_paginate {
            float: none;
            text-align: left;
        }
    }
}

@media only screen and ( min-width: 950px ) and ( max-width: 1125px ) {
    .dt-button-text {
        display: none;
    }
}

@media only screen and ( min-width: 950px ) {
    .table_entries,
    .table_controls {
        border-top: 0;
        clear: none;
        margin: 0;
        padding: 0;
    }
}

@media only screen and (min-width: 1500px) {
    .dataTables_wrapper {
        .dataTables_paginate {
            span {
                .paginate_button,
                .ellipsis {
                    display: inline-block;
                }
            }
        }
    }
}
