$font-monospace: "Courier New", Courier, monospace;

$language-footer-min-height: 20px;
$table-border-color: #BCBCBC;
$table-header-background: #FFF;
$table-odd-row: #f3f4f4;

$nav-menu-bullet: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='5' height='5' viewBox='0 0 25 25'%3E%3Cpath fill='%23999' d='M9.66.95h4.56l9.21 11.85-9.21 10.53H9.66l5.08-10.53z'/%3E%3C/svg%3E");

@mixin default-button {
    background: #FFF none;
    border: 1px solid #555;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    font-weight: normal;
    line-height: 1.42857143;
    margin-bottom: 0;
    text-align: center;
    touch-action: manipulation;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;

    &:hover {
        background-color: #e0e0e0;

        &:active {
            box-shadow: none;
        }
    }

    &:active:hover {
        background-color: #d4d4d4;
        border-color: #8c8c8c;
    }
}

@mixin primary-button {
    background: $btn-primary none;
    border: 1px solid $btn-primary;
    border-radius: 4px;
    cursor: pointer;
    display: inline-block;
    font-weight: normal;
    line-height: 1.42857143;
    margin-bottom: 0;
    text-align: center;
    touch-action: manipulation;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;

    &:hover,
    &:active,
    &:focus {
        background: $btn-primary-hover none;
        border: 1px solid $btn-primary;
    }

    &:active,
    &:focus {
        box-shadow: inset 0 0 0 1px darken( desaturate( $btn-primary, 15 ), 5 );
    }
}

@mixin disabled-button {
    background: #EEE none;
    border: 1px solid #C0C0C0;
}

@mixin card {
    margin-bottom: 1rem;
    padding: 1rem;
    &:not(.bg-danger):not(.bg-warning):not(.bg-info):not(.bg-success):not(.bg-primary):not(.action) {
        background-color: white;
    }
}

/* Copied from Bootstrap 5 */
@mixin clearfix() {
    &::after {
        clear: both;
        content: "";
        display: block;
    }
}
