$bezier: cubic-bezier(0.23, 1, 0.32, 1);
$slideTime: 400ms;


$daySize: 39px !default;
$padding: calc( $daySize / 16 );
$dayMargin: 2px !default;
$daysWidth: $daySize * 7 + $dayMargin * 14 + $padding * 2 + 2;
$calendarWidth: $daysWidth;

$monthNavHeight: 34px !default;
$weekdaysHeight: 28px !default;
$timeHeight: 40px;



$calendarBackground: #ffffff !default;
$calendarBorderColor: #e6e6e6 !default;

$monthForeground: rgba(black, 0.9) !default;
$arrow_hover_color: #f64747 !default;

$monthBackground: transparent !default;

$weekdaysBackground: transparent !default;
$weekdaysForeground: rgba(black, 0.54) !default;

$dayForeground: #000 !default;
$dayHoverBackground: #e6e6e6 !default;

$todayColor: #538200 !default;
$selectedDayBackground: #FFF4C6 !default;
$selectedDayForeground: #000;

$invertedBg: invert($calendarBackground);

$timecontrols: 25px;
